/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import {handleResponse} from '@dv/shared/angular';
import {WorkTimeControlService} from '@dv/shared/backend/api/work-time-control.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxAngestellteWorkDay} from '@dv/shared/backend/model/jax-angestellte-work-day';
import type {JaxAngestellteWorkDays} from '@dv/shared/backend/model/jax-angestellte-work-days';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import {DvbDateUtil, DvbRestUtil, isNullish, isPresent} from '@dv/shared/code';
import {patchState, signalStore, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {setAllEntities, updateEntity, withEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {pipe, switchMap, tap} from 'rxjs';
import type {
    SortBy,
    SortOrder,
    WorkTimeControllingHoursDaily,
    WorkTimeControllingTableData,
} from '../work-time-controlling-table/work-time-controlling-table.models';
import {WarningType} from '../work-time-controlling-table/work-time-controlling-table.models';

type State = {
    isLoading: boolean;
    selectedMonth: moment.Moment;
    sortOrder: SortOrder;
    sortBy: SortBy;
};

const initialState: State = {
    isLoading: false,
    selectedMonth: DvbDateUtil.startOfMonth(DvbDateUtil.today()),
    sortOrder: 'asc',
    sortBy: 'displayname',
};

function sumMinutes(
    controlData: JaxAngestellteWorkDays,
    accessor: (workDay: JaxAngestellteWorkDay) => number,
): number {
    return controlData.angestellteWorkDays.map(workDay => accessor(workDay))
        .reduce((a, b) => a + b, 0);
}

export const MandantWorkTimeControllingStore = signalStore(
    withDevtools('workTimeControl'),
    withEntities<WorkTimeControllingTableData>(),
    withState<State>(initialState),
    withComputed(
        store => ({
            sortedData: computed<WorkTimeControllingTableData[]>(() => {
                const entities = store.entities();
                const sortBy = store.sortBy();
                const sortOrder = store.sortOrder() === 'asc' ? 1 : -1;

                return entities.slice().sort((a, b) => {
                    switch (sortBy) {
                        case 'targetMinutes':
                            return (a.targetMinutes - b.targetMinutes) * sortOrder;
                        case 'plannedMinutes':
                            return (a.plannedMinutes - b.plannedMinutes) * sortOrder;
                        case 'actualMinutes':
                            return (a.actualMinutes - b.actualMinutes) * sortOrder;
                        case 'absenceMinutes':
                            return (a.absenceMinutes - b.absenceMinutes) * sortOrder;
                        case 'displayname':
                        default:
                            return a.angestellteDisplayName.localeCompare(b.angestellteDisplayName) * sortOrder;
                    }
                });
            }),
        }),
    ),
    withMethods((
        store,
        service: WorkTimeControlService = inject(WorkTimeControlService),
    ) => ({
        startLoading() {
            patchState(store, {isLoading: true});
        },
        load: rxMethod<moment.Moment>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            switchMap(selectedMonth => service.getAll$({
                    workTimeControlling: {
                        stichtag: DvbRestUtil.momentToLocalDateChecked(selectedMonth),
                    },
                }).pipe(
                    handleResponse({
                        next: (data: JaxWorkTimeControlling) => {
                            const wtcData: WorkTimeControllingTableData[] = data.angestellteWorkDays.map(d => {
                                const dailyData: WorkTimeControllingTableData = {
                                    id: d.angestellteId,
                                    angestellteDisplayName: d.angestellteDisplayName,
                                    targetMinutes: sumMinutes(d, daily => daily.targetMinutes),
                                    plannedMinutes: sumMinutes(d, daily => daily.plannedMinutes),
                                    actualMinutes: sumMinutes(d, daily => daily.actualMinutes),
                                    absenceMinutes: sumMinutes(d, daily => daily.absenceMinutes),
                                    ferienkontingent: 0,
                                    expanded: false,
                                    dailyHours: d.angestellteWorkDays.map(daily => {
                                        const missingAbsenceTimes =
                                            isPresent(daily.absences.find(a => isNullish(a.von) || isNullish(a.bis)));

                                        const warning: WarningType | undefined = missingAbsenceTimes ?
                                            WarningType.MISSING_ABSENCE_TIME : undefined;

                                        return {
                                            expanded: false,
                                            date: daily.date,
                                            targetMinutes: daily.targetMinutes,
                                            plannedMinutes: daily.plannedMinutes,
                                            actualMinutes: daily.actualMinutes,
                                            absenceMinutes: daily.absenceMinutes,
                                            ferienkontingent: 0,
                                            warning,
                                        } satisfies WorkTimeControllingHoursDaily;
                                    }).sort((a, b) => a.date.localeCompare(b.date)),
                                };

                                const warnings = dailyData.dailyHours.map(daily => daily.warning)
                                    .filter(warning => isPresent(warning));
                                if (warnings.length > 0) {
                                    dailyData.warning = warnings[0];
                                }

                                return dailyData;
                            });

                            patchState(store, setAllEntities(wtcData));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
        applyMonth(month: moment.Moment) {
            patchState(store, {selectedMonth: month});
        },
        toggleSort(sortBy: SortBy) {
            patchState(store, {
                sortBy,
                sortOrder: store.sortBy() === sortBy && store.sortOrder() === 'asc' ? 'desc' : 'asc',
            });
        },
        toggleAngestellte(angestellteId: string) {
            const current = store.entityMap()[angestellteId];
            patchState(store, updateEntity({id: angestellteId, changes: {expanded: !current.expanded}}));
        },
        toggleDay(angestellteId: string, date: BackendLocalDate) {
            const current = store.entityMap()[angestellteId];
            const dailyHours = current.dailyHours.slice();
            const dateIndex = dailyHours.findIndex(daily => daily.date === date);
            if (dateIndex < 0) {
                return;
            }
            dailyHours[dateIndex] = {...dailyHours[dateIndex], expanded: !dailyHours[dateIndex].expanded};

            patchState(store, updateEntity({id: angestellteId, changes: {dailyHours}}));
        },
    })),
    withHooks({
        onInit(store) {
            store.load(store.selectedMonth);
        },
    }),
);
