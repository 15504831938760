import {ChangeDetectionStrategy, Component, computed, inject, input, output, Signal} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, Validators} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import type {FormGroupSpec} from '@dv/shared/angular';
import {JaxCreateWorkTimeModel} from '@dv/shared/backend/model/jax-create-work-time-model';
import {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import {JaxUpdateWorkTimeModel} from '@dv/shared/backend/model/jax-update-work-time-model';
import {checkPresent, hasOwnPropertyGuarded, isPresent} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

interface WorkTimeModelForm {
    name: string;
    weeklyHours?: number;
}

@Component({
    selector: 'dv-work-time-model-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SubmitCancelButtonsComponent,
        TranslateModule,
        TooltipModule,
    ],
    templateUrl: './work-time-model-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeModelFormComponent {

    public model = input<JaxReadWorkTimeModel | JaxCreateWorkTimeModel>();
    public isLoading = input<boolean>(false);

    public readonly create = output<JaxCreateWorkTimeModel>();
    public readonly update = output<JaxUpdateWorkTimeModel>();
    public readonly cancel = output<void>();

    private readonly errorService = inject(ErrorService);
    private readonly fb = inject(UntypedFormBuilder);

    public form: Signal<FormGroupSpec<WorkTimeModelForm>> = computed(() => {
        const formGroup = this.fb.group({
            name: this.fb.control(this.model()?.name, Validators.required),
        });

        if (!this.model()) {
            const weeklyHours = this.fb.control('', [Validators.required, Validators.min(0)]);
            formGroup.addControl('weeklyHours', weeklyHours);
        }

        return formGroup;
    });

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.form().valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const {name, weeklyHours} = this.form().value;

        const model = this.model();
        if (isPresent(model) && hasOwnPropertyGuarded(model, 'id')) {
            this.update.emit({
                id: model.id as string,
                name: checkPresent(name),
            });

            return;
        }

        this.create.emit({
            name: checkPresent(name),
            weeklyHours: checkPresent(weeklyHours),
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancel.emit();
    }
}
