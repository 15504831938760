<h2 [translate]="titleKey()"></h2>

<form #form="ngForm" (ngSubmit)="submitForm(form)" class="dvb-form">
    <div class="row form-row">
        <div class="col-md-12">
            <input type="text"
                   class="form-control"
                   name="name"
                   [(ngModel)]="name"
                   required
                   maxlength="255"
                   [placeholder]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NAME' | translate"
                   [tooltip]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NAME' | translate"
                   [attr.aria-label]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NAME' | translate">
        </div>
    </div>
    <div class="row form-row">
        <div class="col-md-12">
            <fieldset>
                <legend><h3>{{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.PAID_UNPAID' | translate }}</h3></legend>
                <p class="nl2br">{{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.PAID_UNPAID_HINT' | translate }}</p>
                <div class="radio">
                    <label>
                        <input type="radio" name="paid" [(ngModel)]="paid" [value]="true" required>
                        {{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.PAID' | translate }}
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" name="paid" [(ngModel)]="paid" [value]="false" required>
                        {{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.UNPAID' | translate }}
                    </label>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row form-row">
        <div class="col-md-12">
            <fieldset>
                <legend><h3>{{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.HOLIDAYS_TITLE' | translate }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               name="deductHolidays"
                               [(ngModel)]="deductHolidays"
                               [disabled]="disableHolidays()"/>
                        {{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.HOLIDAYS' | translate }}
                    </label>
                </div>
            </fieldset>
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()"/>
</form>
