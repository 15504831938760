import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    OnInit,
    output,
    signal,
} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {isPresent} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dv-absence-type-form',
    standalone: true,
    imports: [
        SubmitCancelButtonsComponent,
        TooltipModule,
        TranslateModule,
        FormsModule,
    ],
    templateUrl: './absence-type-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceTypeFormComponent implements OnInit {

    public absenceType = input<JaxWorkTimeControllingAbsenceType>();
    public isLoading = input<boolean>(false);

    public readonly save = output<JaxWorkTimeControllingAbsenceType>();
    public readonly cancel = output<void>();

    private readonly errorService = inject(ErrorService);

    private isEditMode = computed(() => isPresent(this.absenceType()?.id));
    public titleKey = computed(() => this.isEditMode() ?
        'WORK_TIME_CONTROLLING.ABSENCE_TYPES.EDIT' :
        'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NEW');

    public name = signal('');
    public paid = signal(true);
    public deductHolidays = signal(false);

    public disableHolidays = computed(() => !this.paid());

    // noinspection JSUnusedLocalSymbols
    private disableHolidaysWhenUnpaid = effect(() => {
        if (this.disableHolidays()) {
            this.deductHolidays.set(false);
        }
    }, {allowSignalWrites: true});

    public ngOnInit(): void {
        const absenceType = this.absenceType();
        if (absenceType) {
            this.name.set(absenceType.name);
            this.paid.set(absenceType.paid);
            this.deductHolidays.set(absenceType.deductHolidays);
        }
    }

    public submitForm(form: NgForm): void {
        this.errorService.clearAll();
        if (!form.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        this.save.emit({
            id: this.absenceType()?.id,
            name: this.name(),
            paid: this.paid(),
            deductHolidays: this.deductHolidays(),
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancel.emit();
    }
}
