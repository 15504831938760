<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <h1 [tooltip]="'WORK_TIME_CONTROLLING.TITLE' | translate" translate="WORK_TIME_CONTROLLING.TITLE"></h1>
            <div class="header-dropdown btn-group" dropdown>
                <button type="button"
                        class="btn btn-link btn-link-dropdown dropdown-toggle floating-text"
                        [attr.aria-label]="'COMMON.MENU' | translate"
                        dropdownToggle>
                    <i class="fa fa-chevron-down"></i>
                </button>
                <ul class="dropdown-menu" *dropdownMenu>
                    <li>
                        <a translate="WORK_TIME_CONTROLLING.ABSENCE_TYPES.TITLE"
                           [uiSref]="absenceTypesState.name!">
                        </a>
                    </li>
                </ul>
            </div>

            <nav>
                <ul class="list-inline header-breadcrumb">
                    @for (state of states; track state.name) {
                        <li uiSrefActiveEq="active">
                            <a [uiSref]="state.name"
                               [translate]="state.data.subtitle">
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    </div>
</div>

<ui-view></ui-view>
