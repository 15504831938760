import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {Ng2StateDeclaration} from '@uirouter/angular';
import {AbsenceTypesComponent} from './absence-type/absence-types/absence-types.component';
import {
    MandantWorkTimeControllingComponent,
} from './mandant-work-time-controlling/mandant-work-time-controlling.component';
import {WorkTimeControllingComponent} from './work-time-controlling/work-time-controlling.component';
import {WorkTimeModelsComponent} from './work-time-model/work-time-models/work-time-models.component';
import {WorkTimeSettingsComponent} from './work-time-settings/work-time-settings.component';

export const WORK_TIME_CONTROLLING_STATE = {
    name: 'base.work-time-controlling',
    url: '/arbeitszeitkontrolle',
    redirectTo: 'base.work-time-controlling.control',
    component: WorkTimeControllingComponent,
    data: {
        permission: PERMISSION.MODULE.WORK_TIME_CONTROLLING,
    },
} satisfies Ng2StateDeclaration;

export const CONTROL_STATE = {
    name: `${WORK_TIME_CONTROLLING_STATE.name}.control`,
    url: '/kontrolle',
    component: MandantWorkTimeControllingComponent,
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.CONTROL.TITLE',
        permission: PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_VIEW + WILDCARD_TOKEN,
    },
} satisfies Ng2StateDeclaration;

export const SETTINGS_STATE = {
    name: `${WORK_TIME_CONTROLLING_STATE.name}.settings`,
    url: '/einstellungen',
    component: WorkTimeSettingsComponent,
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.SETTINGS.TITLE',
    },
} satisfies Ng2StateDeclaration;

export const WORK_TIME_MODELS_STATE = {
    name: `${WORK_TIME_CONTROLLING_STATE.name}.arbeitszeitmodell`,
    url: '/arbeitszeitmodell',
    component: WorkTimeModelsComponent,
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE',
        permission: PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_MODEL_MANAGE + WILDCARD_TOKEN,
    },
} satisfies Ng2StateDeclaration;

export const ABSENCE_TYPES_STATE = {
    name: `${WORK_TIME_CONTROLLING_STATE.name}.absencetypes`,
    url: '/absenztypen',
    component: AbsenceTypesComponent,
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.TITLE',
    },
} satisfies Ng2StateDeclaration;
