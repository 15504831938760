/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';

export type SortOrder = 'asc' | 'desc';
export type SortBy = 'displayname' | 'targetMinutes' | 'plannedMinutes' | 'actualMinutes' | 'absenceMinutes';

export enum WarningType {
    MISSING_ABSENCE_TIME = 'MISSING_ABSENCE_TIME',
}

export const warningClasses: Record<WarningType, string> = {
    MISSING_ABSENCE_TIME: 'missing-absence-time',
};

export type WorkTimeControllingHoursDaily = {
    date: BackendLocalDate;
    targetMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    ferienkontingent: number;
    expanded: boolean;
    warning: WarningType | undefined;
};

export type WorkTimeControllingTableData = {
    id: string;
    angestellteDisplayName: string;
    targetMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    ferienkontingent: number;
    expanded: boolean;
    dailyHours: WorkTimeControllingHoursDaily[];
    warning?: WarningType;
};
